<template>
  <div>
    <v-container fluid>
    <v-toolbar-title>{{ $t('Home') }}</v-toolbar-title>
      <v-row class="mt-2">
        <v-col cols="3">
          <v-card color="warning" height="100">
            <h3 class="text-center pt-5">{{ $t("Customers") }}</h3>
            <h2 class="text-center pt-3">{{ system_data.customerCount }}</h2>
          </v-card>
        </v-col>

        <v-col cols="3">
          <v-card color="success" height="100">
            <h3 class="text-center pt-5">{{ $t("Groups") }}</h3>
            <h2 class="text-center pt-3">{{ system_data.groupCount }}</h2>
          </v-card>
        </v-col>

        <v-col cols="3">
          <v-card color="info" height="100">
            <h3 class="text-center pt-5">{{ $t("Ingredients") }}</h3>
            <h2 class="text-center pt-3">
              {{ system_data.ingredientCount }}
            </h2>
          </v-card>
        </v-col>

        <v-col cols="3">
          <v-card color="danger" height="100">
            <h3 class="text-center pt-5">{{ $t("NonIngredients") }}</h3>
            <h2 class="text-center pt-3">
              {{ system_data.nonIngredientCount }}
            </h2>
          </v-card>
        </v-col>

         <v-col cols="3">
          <v-card color="success" height="100">
            <h3 class="text-center pt-5">{{ $t("Packages") }}</h3>
            <h2 class="text-center pt-3">{{ system_data.packageCount }}</h2>
          </v-card>
        </v-col>

        <v-col cols="3">
          <v-card color="warning" height="100">
            <h3 class="text-center pt-5">{{ $t("Recipes") }}</h3>
            <h2 class="text-center pt-3">{{ system_data.recipesCount }}</h2>
          </v-card>
        </v-col>

        
        <v-col cols="3">
          <v-card color="danger" height="100">
            <h3 class="text-center pt-5">{{ $t("ActiveSubscribes") }}</h3>
            <h2 class="text-center pt-3">
              {{ system_data.subscribeCount }}
            </h2>
          </v-card>
        </v-col>

         <v-col cols="3">
          <v-card color="info" height="100">
            <h3 class="text-center pt-5">{{ $t("InActiveSubscribes") }}</h3>
            <h2 class="text-center pt-3">
              {{ system_data.subscribeCount }}
            </h2>
          </v-card>
        </v-col>

      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "home",
  data() {
    return {
      system_data: {}
    };
  },
  methods: {
    initData() {
       this.$store.dispatch(`zoho/refreshAccessToken`).then((res) => {
        localStorage.setItem('Zoho-Authorization', res.access_token)
      });
      this.$store.dispatch(`home/getData`).then((res) => {
        this.system_data = res.data;
      });
    },
  },
  created() {
    this.initData();
  },
};
</script>
